/**
 * Scripts for Frontend Website
 */ 
 $(document).ready(function() {
  var pageHome = function() {
    var selected = function () {
      $('#filter-status').val(lbFilter.status);
      $('#filter-instansi-id').val(lbFilter.instansi_id);
    }
    var lbLocationHandler = function() {
      $('#tambah-lokasi').click(function() {
        $('#with-location').val('1')
        $('#box-location-picker').show()
      })

      $('#lb-location').locationpicker({
        location: { latitude: -7.389317699999999, longitude: 109.36326150000002 },
        enableAutocomplete: true,
        enableReverseGeocode: true,
        radius: 0,
        inputBinding: {
          latitudeInput: $('#lh-lat'),
          longitudeInput: $('#lh-long'),
          radiusInput: $('#lh-radius'),
          locationNameInput: $('#lh-address-name')
        },
        onchanged: function (currentLocation, radius, isMarkerDropped) {
          var addressComponents = $(this).locationpicker('map').location.addressComponents;
        }
      });
    }

    var lbImageHandler = function() {
      var $uploadCrop

      var showLoading = function() {
        $('#lb-croppie-loading').show()
        $('#lb-croppie-body').css('visibility', 'hidden')
      }

      var hideLoading = function() {
        $('#lb-croppie-loading').hide()
        $('#lb-croppie-body').css('visibility', 'visible')
      }

      $('#tambah-file').click(function() {
        $('#file-attachment').click()
      })

      var setCroppieData = function(input) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();          
          var img = new Image();

          reader.onload = function ( e ) {
            img.onload = function () {
              var canvas = document.createElement( 'canvas' );
              var ctx = canvas.getContext( "2d" );
              var width = img.width;
              var height = img.height;
              var MAX_WIDTH = 900;
              var MAX_HEIGHT = 900;

              // Resize maintaining aspect ratio
              if ( width > height ) {
                if ( width > MAX_WIDTH ) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if ( height > MAX_HEIGHT ) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;

              // Copy the image to the canvas and resize it.
              ctx.drawImage( img, 0, 0, width, height );

              var dataURL = canvas.toDataURL( 'image/png' )

              $('#attachment').val(dataURL)
            }

            img.src = e.target.result;
          }
          reader.readAsDataURL(input.files[0]);
        }
      }

      $('#file-attachment').change(function(e) {
        setCroppieData(this)
      })
    }

    selected()
    lbLocationHandler()
    lbImageHandler()
  }
  var pageDetail = function() {
    if(!window.mylatitude) return
      $('#lb-location-detail').locationpicker({
        location: { latitude: mylatitude, longitude: mylongitude },
        enableAutocomplete: true,
        enableReverseGeocode: true,
        draggable: false,
        radius: 0,
        onchanged: function (currentLocation, radius, isMarkerDropped) {
          var addressComponents = $(this).locationpicker('map').location.addressComponents;
        }
      });
  }

  pageHome()
  pageDetail()

});